<!-- nice본인인증 최초 페이지로 작성 되었으나 현재는 미사용 (2023-05-18, James)-->
<template>
  <div>
    <form
      ref="nice_form"
      name="form_chk"
      method="post"
      action="https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb"
    >
      <input type="hidden" name="m" value="checkplusService" />
      <input type="hidden" ref="encData" name="EncodeData" />
    </form>
  </div>
</template>

<script>
export default {
  name: 'nice.cert.req.popup',
  data() {
    return {
      nice: {
        sEncData, //암호화된 데이터
      },
    };
  },
  mounted() {
    /*********************************************
     * enc_data 얻은후 나이스평가정보 본인인증창 오픈 처리
     **********************************************/
    this.$http.post('/common/nice-main').then((result) => {
      if (result.statusCode === 200 && result.data.return === 0) {
        this.$refs.encData.value = result.data.encdata;
        this.$storage.setSessionStorage('REQ_SEQ', result.data.encdata);
        this.$refs.nice_form.submit();
      } else {
        this.$eventBus.$emit('alert', '알림', '[' + result.data.return + ']' + result.data.message);
      }
    });
  },
};
</script>

<style scoped />
