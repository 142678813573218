import { render, staticRenderFns } from "./NiceFail.vue?vue&type=template&id=c6585aec&scoped=true&"
import script from "./NiceFail.vue?vue&type=script&lang=js&"
export * from "./NiceFail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6585aec",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/buildagent/work/3684aff05d5d78ea/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c6585aec')) {
      api.createRecord('c6585aec', component.options)
    } else {
      api.reload('c6585aec', component.options)
    }
    module.hot.accept("./NiceFail.vue?vue&type=template&id=c6585aec&scoped=true&", function () {
      api.rerender('c6585aec', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/common/components/NiceFail.vue"
export default component.exports