var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "form",
      {
        ref: "nice_form",
        attrs: {
          name: "form_chk",
          method: "post",
          action:
            "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb",
        },
      },
      [
        _c("input", {
          attrs: { type: "hidden", name: "m", value: "checkplusService" },
        }),
        _c("input", {
          ref: "encData",
          attrs: { type: "hidden", name: "EncodeData" },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }